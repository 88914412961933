import { render, staticRenderFns } from "./CatalogStatus.vue?vue&type=template&id=20d42a38"
import script from "./CatalogStatus.vue?vue&type=script&lang=js"
export * from "./CatalogStatus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports